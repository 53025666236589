import { useCallback } from "react";
import type { Container, Engine } from "tsparticles-engine";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim"; 
import "./back.css"
export const Background = () => {
    const particlesInit = useCallback(async (engine: Engine) => {
        console.log(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        await console.log(container);
    }, []);
    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: "#000000",
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 150,
                            duration: 5,
                        },
                    },
                },
                particles: {
                    color: {
                        value: ["#ffffff", "rgba(199, 191, 0, 1)", "rgba(0, 240, 255, 0.56)", "rgba(112, 0, 255, 0.56)", "rgba(240, 20, 47, 0.78)"], // Зірки будуть білими
                    },
                    links: {
                        color: "#ffffff",
                        distance: 50, // Збільшена відстань між зірками
                        enable: true,
                        opacity: 0.1, // Зменшена прозорість зв'язків
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        random: false,
                        speed: 1, // Зменшена швидкість для більш спокійного ефекту
                        straight: true,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 130, // Збільшено кількість зірок
                    },
                    opacity: {
                        value: { min: 0.2, max: 0.8 }, // Зміна прозорості
                        animation: {
                            enable: true,
                            speed: 2,
                            minimumValue: 0.2,
                            sync: false,
                        },
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                        animation: {
                            enable: true,
                            speed: 3,
                            minimumValue: 1,
                            sync: false,
                        },
                    },
                },
                detectRetina: true,
            }}
            
        />
    );
};