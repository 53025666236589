import { Formm } from "./Form/Formm";
import "./Contact.css";
import { useLanguge } from "../Context/Context";
import { useEffect, useRef } from "react";


export const Contact = () =>{
    const lan = useLanguge();
    const forcont = useRef<HTMLDivElement|null>(null);
    useEffect(() =>{
        function anima(){
            forcont.current?.classList.add("tans");
        }
        requestAnimationFrame(anima);
    },[]);
    return(
        <div className="form-container" ref={forcont}>
            <h1 className="conta">
            {lan?.language === "Ua" ? "Поговорим" : ""}
            {lan?.language === "Cz" ? "Promluvme si" : ""}
            {lan?.language === "En" ? "Let's talk" : ""}
            </h1>
             <Formm></Formm>
        </div>
    );
}