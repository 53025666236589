import { Link, useLocation } from "react-router-dom";
import './NavBar.css';
import img from "./Bez názvu-1 kopie.svg";
import { useEffect, useMemo, useState } from "react";
import { useLanguge } from "../Context/Context";

interface Navigationn {
      name: string,
      path: string
   };



export const NavBar = () =>{
    const lan = useLanguge();
    useEffect(() =>{
        function f() {
            menuHendler(false);
            document.querySelector(".linee1")?.classList.add("move1back");
            document.querySelector(".linee2")?.classList.add("move2back");
            document.querySelector(".linee3")?.classList.add("move3back"); 
            document.querySelector(".linee1")?.classList.remove("move1");
            document.querySelector(".linee2")?.classList.remove("move2");
            document.querySelector(".linee3")?.classList.remove("move3"); 
        }
        document.addEventListener("scroll", f)
        return () =>{document.removeEventListener("scroll", f)}
    }, []);
    const [menu, menuHendler] = useState<boolean>(false);
    const change = () =>{
        menuHendler(!menu); 
        console.log("fff");
        if(!menu){
            document.querySelector(".linee1")?.classList.add("move1");
            document.querySelector(".linee2")?.classList.add("move2");
            document.querySelector(".linee3")?.classList.add("move3"); 
            document.querySelector(".linee1")?.classList.remove("move1back");
            document.querySelector(".linee2")?.classList.remove("move2back");
            document.querySelector(".linee3")?.classList.remove("move3back"); 
        }
        else{
            document.querySelector(".linee1")?.classList.add("move1back");
            document.querySelector(".linee2")?.classList.add("move2back");
            document.querySelector(".linee3")?.classList.add("move3back"); 
            document.querySelector(".linee1")?.classList.remove("move1");
            document.querySelector(".linee2")?.classList.remove("move2");
            document.querySelector(".linee3")?.classList.remove("move3"); 
        }
       
    }
    const location = useLocation();
    const bur = (<><div className="linee1"></div><div className="linee2"></div><div className="linee3"></div></>);
    const nav : Navigationn[] =  [
        {
            name: "About",
            path: "/"
        },
        {
            name: "Resume",
            path: "/resume"
        },
        {
            name: "Projects",
            path: "/projects"
        },
        {
            name: "Contact",
            path: "/contact"
        }
    ];
    nav[0].name = lan?.language === "Ua"  ? "про мене" : nav[0].name;
    nav[0].name = lan?.language === "Cz"  ? "o mně" : nav[0].name;
    nav[1].name = lan?.language === "Ua"  ? "Резюме" : nav[1].name;
    nav[1].name = lan?.language === "Cz"  ? "resumé" : nav[1].name;
    nav[2].name = lan?.language === "Ua"  ? "Проекти" : nav[2].name;
    nav[2].name = lan?.language === "Cz"  ? "projekty" : nav[2].name;
    nav[3].name = lan?.language === "Ua"  ? "Контакт" : nav[3].name;
    nav[3].name = lan?.language === "Cz"  ? "kontakt" : nav[3].name;
    return(
        <nav id="navig">
            <div id="logo">
                <img src={img} alt="logo" />
            </div>
            <div>
                {window.innerWidth > 1023 ? (<ul className="navi">{nav.map((f, i)=><Link className={`Iteem ${location.pathname === f.path ? 'active' : 'pasive'}`} key={i} to={f.path}>{f.name}</Link>)}</ul>) : (<div id="burger" onClick={change}>{bur}</div>)}
            </div>
            {menu && <div id="menucontainer"><div className="menu">{nav.map((f, i)=><Link className="Iteem" onClick={change} key={i} to={f.path}>{f.name}</Link>)}</div></div>}
        </nav> 
    );
}