import img2 from "./discord.png"
import img1 from "./social.png"
import img from "./twitter.png"
import "./InfoLogo.css"
export const InfoLogo = () =>{
    
    return(
        <div id="gjd">
         <a href="https://twitter.com/Roskanuk328" target="blank"><div id="gj" style={{"backgroundImage": `url(${img})`}}></div></a>
         <a href="https://www.instagram.com/roshka_jr?igsh=ZXMwanVxYXBoNzVl&utm_source=qr" target="blank"><div id="gj" style={{"backgroundImage": `url(${img1})`}}></div></a>
         <a href="https://discord.com/users/kings328#5425" target="blank"><div id="gj" style={{"backgroundImage": `url(${img2})`}}></div></a>
        </div>
    );
}