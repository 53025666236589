import { Outlet } from "react-router-dom";
import { NavBar } from "../NavBar/NavBar";
import { Footer } from "../Footer/Footer";
import "./OutL.css";
import { useLanguge } from "../Context/Context";
import "./OutL.css";
import { useEffect, useState } from "react";
import { Background } from "../react-particles/Background";

export const OutL = () => {
  const lan = useLanguge();
  const change = (e: React.ChangeEvent<HTMLSelectElement>) => {
    lan?.changeLanguage(e.target.value);
    console.log(e.target.value);
  };
 
  function f() {
    const scrollStep = window.scrollY / 15;
    const scroll = () => {
      if (window.scrollY > 0) {
        window.scrollBy(0, -scrollStep);
        requestAnimationFrame(scroll);
      }
    };
    requestAnimationFrame(scroll);
  }
  const [opacit, setOpacit] = useState<number>(0);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      setOpacit(window.scrollY / 800);
    });
  }, []);

  return (
    <div>
      <select id="language" onChange={change}>
        <option value="En">{lan?.language === "Ua"  ? "Англійська" : ""}{lan?.language === "Cz"  ? "Angličtina" : ""}{lan?.language === "En"  ? "English" : ""}</option>
        <option value="Ua">{lan?.language === "Ua"  ? "Українська" : ""}{lan?.language === "Cz"  ? "Ukrajinský" : ""}{lan?.language === "En"  ? "Ukrainian" : ""}</option>
        <option value="Cz">{lan?.language === "Ua"  ? "Чеська" : ""}{lan?.language === "Cz"  ? "Česky" : ""}{lan?.language === "En"  ? "Czech" : ""}</option>
      </select>
      {window.scrollY !>= 50 && window.innerWidth > 1000 ? (
        <div className="start-up" onClick={f} style={{ opacity: opacit }}></div>
      ) : (
        <div></div>
      )}
      <NavBar />
      <div className="maim">
        <Outlet />
      </div>
      <Footer></Footer>
      <Background />
    </div>
  );
};
