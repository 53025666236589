import { SkillsInfo } from "./SkillsInfo";
import "./Skills.css";
import { useLanguge } from "../../Context/Context";

export interface Skil {
       name: string,
       params: string[]
    };

   

export const Skills = () =>{
    const lan = useLanguge()
    const skills: Skil[] = [
        {
            name:  "Prog. languages",
            params: ["C#", "Java Script, (React)", "Type Script", '"HTML, CSS"']
        },
        {
            name: 'Graphics and design',
            params: ["Raster graphics", "Vector graphics", "Color models", "Basic formats"]
        },
        {
            name: 'Computer network',
            params: ["Subneting", "API", "IPv4", "TCP/IP Model"]
        },
        {
            name: 'Language',
            params: ["English (B1)", "Ukraine (C2)", "Czech (B2)"]
        },
     ];
    skills[0].name = lan?.language === "Ua"  ? "Мови програмування" : skills[0].name;
    skills[0].name = lan?.language === "Cz"  ? "Prog. jazyky" : skills[0].name;
   
    skills[1].name = lan?.language === "Ua"  ? "Графіка та дизайн" : skills[1].name;
    skills[1].name = lan?.language === "Cz"  ? "Grafika a design" : skills[1].name;

    skills[2].name = lan?.language === "Cz"  ? "Počítačová síťě" : skills[2].name;
    skills[2].name = lan?.language === "Ua"  ? "Комп'ютерна мережі" : skills[2].name;

    skills[3].name = lan?.language === "Ua"  ? "Мови" : skills[3].name;
    skills[3].name = lan?.language === "Cz"  ? "Jazyky" : skills[3].name;
    return(
        <div className="skill">
            <h2 className="infotxt">
            {lan?.language === "Ua" ? "Навички" : ""}
            {lan?.language === "Cz" ? "Dovednosti" : ""}
            {lan?.language === "En" ? "Skills" : ""}
            </h2>
            <SkillsInfo skill={skills}/>
        </div>
    );
};