import { useEffect, useRef } from "react";
import { Stuff } from "../Projects";
import "./ProjectCard.css";
import { useLanguge } from "../../Context/Context";

export const ProjectCard = (props: {
  image: string;
  name: string;
  stuff: Stuff;
  discripton: string;
  index: number;
}) => {
  const f: "row" | "row-reverse" = props.index !== 1 ? "row-reverse" : "row";
  const con = useRef<HTMLDivElement | null>(null);
  const con2 = useRef<HTMLDivElement | null>(null);

  const item1 = useRef<HTMLDivElement | null>(null);
  const item2 = useRef<HTMLDivElement | null>(null);
  const item3 = useRef<HTMLDivElement | null>(null);
  const item4 = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function anima() {
      con.current?.classList.add("move-left");
      con2.current?.classList.add("move-left");
      item1.current?.classList.add("move-left");
      item2.current?.classList.add("move-left");
      item3.current?.classList.add("move-left");
      item4.current?.classList.add("move-left");
    }
    if (window.scrollY >= 600 * props.index) {
      requestAnimationFrame(anima);
    }
    function Listener() { 
      if (window.scrollY >= 600 * props.index) {
      requestAnimationFrame(anima);
    }}
      document.addEventListener("scroll", Listener);
    return () => { document.removeEventListener("scroll", Listener)};
  }, [props.index]);
  const lan = useLanguge();
  return (
    <div>
      <h2 className="projectName">{props.name}</h2>
      <div
        className="ProjSbox"
        style={{ display: "flex", flexDirection: `${f}` }}
      >
        <div className="imgcontainer" ref={con}>
        <div className="image" style={{ backgroundImage: `url(${props.image})` }}></div>
          <p className="discription">{props.discripton}</p>
        </div>
        <div>
          
          <h3 className="stuffIusehere" ref={con2}>
            {lan?.language === "Ua"  ? "Що я використав тут" : ""}
            {lan?.language === "Cz"  ? "Co jsem použil zde" : ""}
            {lan?.language === "En"  ? "Stuff I used here" : ""}
          </h3>
          <div className="stuffContainer" ref={item1}>{props.stuff.stuff1}</div>
          <div className="stuffContainer" ref={item2}>{props.stuff.stuff2}</div>
          <div className="stuffContainer2" ref={item3}>{props.stuff.stuff3}</div>
          <div className="stuffContainer2" ref={item4}>{props.stuff.stuff4}</div>
        </div>
      </div>
    </div>
  );
};
