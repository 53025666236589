import { useEffect, useRef } from "react";
import { Acquisitions } from "./Acquisitions/Acquisitions";
import "./Education.css";
import { useLanguge } from "../../Context/Context";
interface AcquisitionsI {
  mainText: string;
  secondText: string;
  thirdText: string;
  fourthText: string;
}
const data: AcquisitionsI[] = [
  {
    mainText: "2022 - 2026",
    secondText: "Kyberna",
    thirdText: "bachelor's degree",
    fourthText: "https://kyberna.cz/o-skole ",
  },
  {
    mainText: "Cisco certificate",
    secondText: "Introduction to networks",
    thirdText: "2024",
    fourthText: "/RomanRoshkanyuk-CCNAv72_T2_2023--certificate.pdf",
  },
];
export const Education = () => {
  const educ = useRef<HTMLDivElement | null>(null);
  const educ2 = useRef<HTMLDivElement | null>(null);
  const lan = useLanguge();
  useEffect(() => {

    setTimeout(() => {
      educ.current?.classList.add("transfor");
      educ2.current?.classList.add("transfor");
    }, 1);
  
    console.log("wtf");
  }, [lan?.language]);
  data[0].thirdText = lan?.language === "Ua"  ? "ступінь бакалавра" : data[0].thirdText;
  data[0].thirdText = lan?.language === "Cz"  ? "bakalářský titul" : data[0].thirdText;
  data[0].thirdText = lan?.language === "En"  ? "bachelor's degree" : data[0].thirdText;
  return (
    <div className="educat">
      <h1 className="edukat">{lan?.language === "Ua"  ? "Освіта" : ""}{lan?.language === "Cz"  ? "Vzdělávání" : ""}{lan?.language === "En"  ? "Education" : ""}</h1>
      <div className="mainContainer">
        <div className="containerForContainer" ref={educ2}>
          {data.map((e, index) => (
            <Acquisitions
              key={index}
              mainText={e.mainText}
              secondText={e.secondText}
              thirdText={e.thirdText}
              fourthText={e.fourthText}
            />
          ))}
        </div>
        <div className="textContainer" ref={educ}>
          {lan?.language === "Ua"
            ? "Тепер хотілося б розповісти про те, де я навчаюся, це дуже чудовий заклад, після закінчення якого випускають фахівця найвищого рівня і я не виняток, для детального ознайомлення з навчальним закладом ви можете скористатися посиланням на його сайт"
            : ""}
          {lan?.language === "Cz"
            ? "Nyní bych vám chtěl říct o tom, kde studuji, je to velmi skvělá instituce, po které absolvujete jako odborník nejvyšší úrovně a já nejsem výjimkou pro podrobný pohled na školu můžete použít odkaz na její webové stránky."
            : ""}
          {lan?.language === "En"
            ? "Now I would like to tell you about where I study, it is a very wonderful institution after which you graduate as a specialist of the highest level and I am no exception for a detailed view of the school you can use the link to its website"
            : ""}
        </div>
      </div>
    </div>
  );
};
