import { useEffect, useRef } from "react";
import { useLanguge } from "../Context/Context";
import img1 from "./ProjectCard/qwe.gif";
import img3 from "./ProjectCard/rty.jpg";
import img2 from "./ProjectCard/uio.jpg";
import "./Projects.css";
import { ProjectCard } from "./ProjectCard/ProjectCard";
export interface Stuff{
    stuff1: string,
    stuff2: string,
    stuff3: string,
    stuff4: string,
}
export interface Projectscard {
    image: string,
    name: string,
    stuff: Stuff,
    discripton: string
}




export const Projects = () => {
    const lan = useLanguge()
    const projectData: Projectscard[] = [
        {
            image: img1,
            name: "Costs List",
            stuff: {stuff1: "Type script", stuff2: "React hooks", stuff3: "JSX", stuff4: "React components",},
            discripton: "The Cost List is my first React project where I started working with the React hock and used the component model of the entire project, figured out the virtual DOM and how React renders the entire site, also used child props and learned how to pass data down the hierarchy as well as up, and it turned out to be a pretty good first site to solidify my knowledge. ",
        },
        {
            image: img2,
            name: "Want to be a programmer?",
            stuff: {stuff1: "Figma for design", stuff2: "Java script", stuff3: "CSS Animations", stuff4: "Some SEO",},
            discripton: "Want to be a programmer is a project in which I decided not to use React and try myself in the “pure” Front-end is one of many projects where I tried to do everything at the highest level, even the design, and I also tried to add interactivity with java script and I believe that I even exceeded my task and also to learn what I learned from java script I wrote a slider but without using libraries, I would also like to mention that this site is a static and one-page site, I will be able to tell you more about it in a live conversation.",
        },
        {
            image: img3,
            name: "“Small market”",
            stuff: {stuff1: "React routing", stuff2: "React hooks", stuff3: "Redux", stuff4: "Registration",},
            discripton: "“Small Market” is already a serious website in my opinion, in which I already paid more attention to React, its structure and capabilities, and of course React hacks, for example, useLocation(), useContext, useSearchParams(), useParams() and many others in this project as well, if you read carefully what hacks I used, you will understand that I used Routing as well and here I went even deeper into the structure of React and how it works at the basic level and therefore already a dynamic web with an admin panel and a small registration and I also touched on Redax in it even more details about this project I will be able to tell you live",
        },
    ]
     const f = useRef<HTMLDivElement|null>(null);
     useEffect(() => {
        const addActiveClass = () => {
          f.current?.classList.add("active");
        };
            requestAnimationFrame(addActiveClass);
      }, []);
      projectData[0].discripton = lan?.language === "Ua"  ? "Cost List - це мій перший React-проект, де я почав працювати з React-хоком і використовував компонентну модель всього проекту, розібрався з віртуальним DOM і тим, як React рендерить весь сайт, також використовував дочірні пропси і навчився передавати дані вниз і вгору по ієрархії, і це виявився досить непоганим першим сайтом для закріплення моїх знань." : projectData[0].discripton;
      projectData[0].discripton  = lan?.language === "Cz"  ? "Cost List je můj první projekt v Reactu, kde jsem začal pracovat s React hockem a používal komponentový model celého projektu, zjistil jsem, jak funguje virtuální DOM a jak React vykresluje celý web, také jsem používal child props a naučil se předávat data v hierarchii směrem dolů i nahoru, a ukázalo se, že je to docela dobrý první web k upevnění mých znalostí." : projectData[0].discripton;
      projectData[1].discripton = lan?.language === "Ua"  ? "Want to be a programmer - це проект, в якому я вирішив не використовувати React і спробувати себе в «чистому» Front-end, це один з багатьох проектів, де я намагався зробити все на вищому рівні, навіть дизайн, а також спробував додати інтерактивності за допомогою java script і я вважаю, що навіть перевиконав своє завдання, а також, щоб вивчити те, що я дізнався з java script я написав слайдер, але без використання бібліотек, також хотів би зазначити, що цей сайт статичний і односторінковий, про нього я зможу розповісти докладніше в живому спілкуванні." : projectData[1].discripton;
      projectData[1].discripton  = lan?.language === "Cz"  ? "Want to be a programmer je projekt, ve kterém jsem se rozhodl nepoužívat React a zkusit se v „čistém“ Front-end je jedním z mnoha projektů, kde jsem se snažil udělat vše na nejvyšší úrovni, dokonce i design, a také jsem se snažil přidat interaktivitu s java skriptem a věřím, že jsem dokonce překročil svůj úkol a také se naučit, co jsem se naučil z java skript jsem napsal slider, ale bez použití knihoven, Chtěl bych také zmínit, že tento web je statický a jednostránkový web, budu moci říci více o tom v živém rozhovoru." : projectData[1].discripton;
      projectData[2].discripton = lan?.language === "Ua"  ? "“Small market” - це вже серйозний, на мою думку, сайт, в якому я вже більше уваги приділив React, його структурі та можливостям, і звичайно ж React-хакам, наприклад, useLocation(), useContext, useSearchParams(), useParams() та багатьом іншим, які я використовував і в цьому проекті, якщо ви уважно прочитаєте, які саме хаки я використовував, ви зрозумієте, що я також використовував Routing і тут я ще більше заглибився в структуру React і як він працює на базовому рівні і тому вже динамічний веб з адмін-панеллю і невеликою реєстрацією і я також торкнувся Redax в ньому ще більш детально про цей проект я зможу розповісти вам наживо" : projectData[2].discripton;
      projectData[2].discripton  = lan?.language === "Cz"  ? "“Small market” je podle mého názoru již seriózní web, ve kterém jsem se již více věnoval Reactu, jeho struktuře a možnostem a samozřejmě React hackům, například useLocation(), useContext, useSearchParams(), useParams() a mnoha dalším i v tomto projektu, pokud si pozorně přečtete, jaké hacky jsem použil, pochopíte, že jsem použil i Routing a zde jsem šel ještě hlouběji do struktury Reactu a jak funguje na základní úrovni a tedy již dynamický web s administračním panelem a malou registrací a také jsem se dotkl Redaxu v něm ještě více podrobností o tomto projektu vám budu moci říci naživo" : projectData[2].discripton;
   
      console.log("assd");
      return(
        <div className="projectbox" ref={f}> 
                <h1 className="maintxt"> 
                {lan?.language === "Ua"  ? "Проекти" : ""}
                {lan?.language === "Cz"  ? "Projekty" : ""}
                {lan?.language === "En"  ? "Projects" : ""}</h1>
                {projectData.map((e, index) => <ProjectCard key={index} index={index} image={e.image} discripton={e.discripton} name={e.name} stuff={e.stuff}/>)}
        </div>
    );
}