import { Education } from "./Education/Education";
import { Experience } from "./Experience/Experience";
import { Skills } from "./Skills/Skills";
import "./Resume.css"
import { useEffect, useRef } from "react";
import { useLanguge } from "../Context/Context";
export const Resume = () => {
    const mao = useRef<HTMLDivElement|null>(null);
    useEffect(()=>{
        setTimeout(() =>{mao.current?.classList.add("delay")}, 1)
    },[])
    const lan = useLanguge();
    return(
        <div className="maonbox" ref={mao}>
            <h1 className="resume">
                {lan?.language === "Ua"  ? "Резюме" : ""}
                {lan?.language === "Cz"  ? "Resumé" : ""}
                {lan?.language === "En"  ? "Resume" : ""}</h1>
            <Education/>
            <Experience/>
            <Skills/>
        </div>
    );
}