import "./About.css";
import IMG from "./photo_2024-08-05_23-54-05-Enhanced.jpg";
import { InfoLogo } from "../InfoLogo/InfoLogo";
import { NavigationButtom } from "./navigationButtom/NavigationButtom";
import { useEffect } from "react";
import { useLanguge } from "../Context/Context";
interface navigButton {
  name: string;
  color: string;
  srcc: string;
  width: number;
}
const Width: number = 108;

export const About = () => {
  const navigButtomInfo: navigButton[] = [
    {
      name: "contact",
      color: "rgba(74, 194, 72, 0.70)",
      srcc: "/contact",
      width: Width,
    },
    {
      name: "project",
      color: "rgba(255, 229, 0, 0.70)",
      srcc: "/projects",
      width: Width,
    },
    {
      name: "resume",
      color: "rgba(203, 35, 35, 0.70)",
      srcc: "/resume",
      width: Width,
    },
  ];
  useEffect(() => {
    setTimeout(function(){document.getElementsByClassName("name")[0]?.classList.add("delay");}, 1);
    setTimeout(function(){document.getElementsByClassName("foto")[0]?.classList.add("delay");}, 1);
    setTimeout(function(){document.getElementsByClassName("HiItsMe")[0]?.classList.add("delay");}, 1);
    setTimeout(function(){document.getElementsByClassName("samllTextAboutMe")[0]?.classList.add("delay");}, 1);
  }, []);
  const lan = useLanguge();
  navigButtomInfo[0].name = lan?.language === "Ua"  ? "контакти" : navigButtomInfo[0].name;
  navigButtomInfo[0].name  = lan?.language === "Cz"  ? "kontakt" : navigButtomInfo[0].name;
  navigButtomInfo[1].name = lan?.language === "Ua"  ? "проекти" : navigButtomInfo[1].name;
  navigButtomInfo[1].name  = lan?.language === "Cz"  ? "projekty" : navigButtomInfo[1].name;
  navigButtomInfo[2].name = lan?.language === "Ua"  ? "резюме" : navigButtomInfo[2].name;
  navigButtomInfo[2].name  = lan?.language === "Cz"  ? "resumé" : navigButtomInfo[2].name;
  return (
    <div className="Abou">
      <div className="nameInformation">
        <div className="name">
        {lan?.language === "Ua"  ? "Роман" : ""}
          {lan?.language === "Cz"  ? "Roman" : ""}
          {lan?.language === "En"  ? "Roman" : ""}<br />
           {lan?.language === "Ua"  ? "Рошканюк" : ""}
          {lan?.language === "Cz"  ? "Roškaňuk" : ""}
          {lan?.language === "En"  ? "Roshkanyuk" : ""}
        </div>
        <div className="navigationContainer">
          {navigButtomInfo.map((e, index) => (
            <NavigationButtom
              key={index}
              id={index}
              name={e.name}
              color={e.color}
              width={e.width}
              srcc={e.srcc}
            />
          ))}
        </div>
      </div>
      <div className="Me">
        <div className="foto" style={{ backgroundImage: `url(${IMG})` }}><img src={IMG} alt="me" id="mee"></img></div>
        <InfoLogo/>
      </div>
      <div className="shortResume">
        <h1 className="HiItsMe">    
          {lan?.language === "Ua"  ? "Привіт! Це я." : ""}
          {lan?.language === "Cz"  ? "Ahoj! to jsem já" : ""}
          {lan?.language === "En"  ? "Hi! its me" : ""}</h1>
        <p className="samllTextAboutMe">
        {lan?.language === "Ua"  ? "Я спеціаліст з Front-end, UX, UI та React-розробки з досвідом роботи з C#, JavaScript, TypeScript, HTML та CSS. Крім того, я маю навички роботи з графікою, підмережами, API, IPv4 та TCP/IP." : ""}
          {lan?.language === "Cz"  ? "Jsem front-end, UX, UI a React vývojář se zkušenostmi v jazycích C#, JavaScript, TypeScript, HTML a CSS. Kromě toho mám dovednosti v oblasti grafiky, podsíťování, API, IPv4 a TCP/IP." : ""}
          {lan?.language === "En"  ? "   I am a dedicated Front-end, UX, UI, and React Developer with expertise in C#, JavaScript, TypeScript, HTML, and CSS. Additionally, I have skills in graphics, subnetting, API, IPv4, and TCP/IP." : ""}
       
        </p>
      </div>
    </div>
  );
};
