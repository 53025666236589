import { Link } from "react-router-dom";
import "./navi.css"

export const NavigationButtom = (props : {name: string; color: string, srcc: string, width: number, id: number}) =>{
    const jsxElement = <Link to={props.srcc} className="navigButtom" id={props.color} style={{backgroundColor: props.color, width: props.width, borderRadius: 100 + "px", height: props.width, borderColor: props.color, opacity: 0}}>{props.name}</Link>
    setTimeout(function() {
        const buttons = document.getElementsByClassName('navigButtom');
        for (let i = 0; i < buttons.length; i++) {
            if(i === props.id){
                buttons[i].classList.add('delay'); 
            }
        }
      }, 200 * props.id + 500);
    return(
        jsxElement
    );
}