import "./Footer.css"
import { InfoLogo } from "../InfoLogo/InfoLogo";
export const Footer = () =>{

    return(
        <footer className="foote"> 
            <div className="line"></div>
            <div className="Content">
            <div className="foottxt">
                  © 2024<br />
                  Designed and Created 
                  by Roman Roshkanyuk
            </div>
            <div className="Conect">
                   <div className="cont">
                    <h2 className="co">Call</h2> 
                     <a href="tel:+420605447445" id="call"><p>+420-605-447-445</p></a>
                     <a href="tel:+380997554958" id="call"><p>+380-99-755-49-58</p></a>
                    </div>
                   <div className="cont">
                    <h2 className="co">Write</h2> 
                    <a href="mailto:romanroskanuk74@gmail.com" id="mail">writeme@gmail.com</a>
                    </div>
                   <div className="cont">
                    <h2 className="co">Follow</h2> 
                    <InfoLogo></InfoLogo>
                    </div>
            </div>
            </div>
        </footer>
    );
}