import { createContext, useContext, useState } from "react";

interface context {
  language: string;
  changeLanguage: React.Dispatch<React.SetStateAction<string>>;
}

const response = createContext<context | undefined>(undefined);
export const MyContext = (props: { children: React.ReactNode }) => {
  const [languag, changeLanguageHandler] = useState<string>("En");
  const value: context = {
    language: languag,
    changeLanguage: changeLanguageHandler,
  };
  return <response.Provider value={value}>{props.children}</response.Provider>;
};
export const useLanguge = () => {
  if (response === undefined) {
    throw new Error("useResponseContext must be used within a MyContext");
  }
  return useContext(response);
};
