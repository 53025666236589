import "./Acquisitions.css"

export const Acquisitions = (props: {mainText: string, secondText: string, thirdText: string, fourthText: string}) =>{
    return(
        <div className="continer">
            <h1 className="mt">{props.mainText}</h1>
            <h2 className="st">{props.secondText}</h2>
            <h3 className="tt">{props.thirdText}</h3>
            <a className="ft" href={props.fourthText} target="blank" download="RomanRoshkanyuk-Certificate.pdf">{props.fourthText === "/RomanRoshkanyuk-CCNAv72_T2_2023--certificate.pdf" ? "certificate here" : props.fourthText}</a>
        </div>
    )
    
}