import { useRef, useState } from "react";
import "./Form.css";
import React from "react";
import emailjs from "@emailjs/browser";
import { useLanguge } from "../../Context/Context";
export const Formm = () => {
  const blur = (
    e:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    switch (e.target.name) {
      case "full-name":
        if (!firstName) {
          firstNameBHandler("fill this field");
        }
        break;
      case "email":
        if (!email) {
          emailBHandler("fill this field");
        } else if (!setmail(email)) {
          emailBHandler("email incorect");
        }
        break;
      case "message":
        if (!message) {
          messageBHandler("fill this field");
        }
        break;
    }
  };
  const setmail = (e: string): string | undefined => {
    return validateEmail(e)?.toString();
  };
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const form = useRef<HTMLFormElement | null>(null);
   function f(){
    button.current?.children.namedItem("cont1")?.classList.add("opa");
    button.current?.classList.add("shape");
    button.current?.children.namedItem("cont2")?.classList.add("apo");
   }
  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (setmail(email) && firstName && message) {
    f();
      if (form.current) {
        emailjs
          .sendForm(
            "service_tv98pns",
            "template_2ggdqr4",
            form.current,
            "UYGOpFQrVW7s7TfBq"
          )
          .then(() => {
            button.current?.children.namedItem("cont1")?.classList.remove("opa");
            button.current?.classList.remove("shape");
            button.current?.children.namedItem("cont2")?.classList.remove("apo");
          })
          .catch((e) => {
         throw e;
          });
      }
      firstNameHandler("");
      messageHandler("");
      emailHandler("");
    } else {
      if (!email) {
        emailBHandler("fill this field");
      }
      if (!firstName) {
        firstNameBHandler("fill this field");
      }
      if (!message) {
        messageBHandler("fill this field");
      }
      console.log("ddd");
    }
  };

  const [firstName, firstNameHandler] = useState<string>("");
  const [message, messageHandler] = useState<string>("");
  const [email, emailHandler] = useState<string>("");

  const [firstNameB, firstNameBHandler] = useState<string | null>(null);
  const [emailB, emailBHandler] = useState<string | null>(null);
  const [messageB, messageBHandler] = useState<string | null>(null);

  const button = useRef<HTMLButtonElement | null>(null);
  let buttonClass = "";
  if (firstName === "" || message === "" || !setmail(email)) {
    buttonClass = "but-cor";
  } else {
    buttonClass = "but-ror";
  }
 const lan = useLanguge();
  return (
    <div className="formContainer">
      <div className="wish-container">
        <h2 className="wish">
        {lan?.language === "Ua" ? "Бажаю вам гарного дня і пам'ятайте, що я" : ""}
            {lan?.language === "Cz" ? "Přeji vám hezký den a nezapomeňte, že jsem " : ""}
            {lan?.language === "En" ? 'Wish you a good day, and remember that I' : ""}
        
        </h2>
        <p className="wish-text">
       
          {lan?.language === "Ua" ? 'Я спеціаліст з Front-end, UX, UI та React-розробки з досвідом роботи з C#, JavaScript, TypeScript, HTML та CSS. Серед моїх проектів - «The Cost List», де я вивчаю хуки та компоненти React; «Want to be a Programmer», статичний односторінковий сайт, що демонструє навички JavaScript; та «Small Market», просунутий React-проект з маршрутизацією, адмін-панеллю та Redux. Я здобуваю ступінь бакалавра комп`ютерних наук у Кібернетичному університеті (2022-2026). Крім того, я маю навички роботи з графікою, підмережами, API, IPv4 та TCP/IP. Вільно володію англійською (A2), українською (C2) та чеською (B2) мовами, амбітний, охочий до навчання та готовий до нових викликів.' : ""}
            {lan?.language === "Cz" ? " Jsem front-end, UX, UI a React vývojář se zkušenostmi v jazycích C#, JavaScript, TypeScript, HTML a CSS. Mezi mé projekty patří „The Cost List“, kde si osvojuji React hooks a komponenty; „Want to be a Programmer“, statický jednostránkový web demonstrující dovednosti v JavaScriptu; a „Small Market“, pokročilý projekt React se směrováním, administračním panelem a Reduxem. Na Kyberně studuji bakalářské studium informatiky (2022-2026). Kromě toho mám dovednosti v oblasti grafiky, podsíťování, API, IPv4 a TCP/IP. Plynně hovořím anglicky (A2), ukrajinsky (C2) a česky (B2), jsem ambiciózní, dychtivý učit se a připravený na nové výzvy." : ""}
            {lan?.language === "En" ? ' I am a dedicated Front-end, UX, UI, and React Developer with expertise in C#, JavaScript, TypeScript, HTML, and CSS. My projects include "The Cost List," mastering React hooks and components; "Want to be a Programmer," a static one-page site demonstrating JavaScript skills; and "Small Market," an advanced React project with routing, an admin panel, and Redux. I am pursuing a Bachelor’s Degree in Computer  Science at Kyberna (2022-2026). Additionally, I have skills in graphics, subnetting, API, IPv4, and TCP/IP. Fluent in English (A2), Ukrainian (C2), and Czech (B2), I am ambitious, eager to learn, and ready for new challenges.' : ""}
        </p>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <div className="peaceContainer">
          <label className="lab">
             
            {lan?.language === "Ua" ? "Прізвище та ім'я" : ""}
            {lan?.language === "Cz" ? "Jméno a příjmení" : ""}
            {lan?.language === "En" ? 'Full Name' : ""}
            <div className="erorr"> {firstNameB ? " * " : ""}</div>
          </label>{" "}
          <input
            className="inp"
            type="text"
            onClick={() => {
              firstNameBHandler(null);
            }}
            placeholder={firstNameB ?? ""}
            name="full-name"
            onChange={(e) => firstNameHandler(e.target.value)}
            value={firstName}
            onBlur={blur}
          />
        </div>
        <div className="peaceContainer">
          <label className="lab">
          {lan?.language === "Ua" ? "Емейл" : ""}
            {lan?.language === "Cz" ? "Email" : ""}
            {lan?.language === "En" ? 'Email' : ""}{" "}
            <div className="erorr">
              {" "}
              {emailB
                ? " * " + (emailB === "email incorect" ? emailB : "")
                : ""}
            </div>
          </label>{" "}
          <input
            className="inp"
            type="text"
            onClick={() => emailBHandler(null)}
            placeholder={emailB ?? ""}
            name="email"
            onChange={(e) => emailHandler(e.target.value)}
            value={email}
            onBlur={blur}
          />
        </div>
        <div className="peaceContainer">
          <label className="lab">
          {lan?.language === "Ua" ? "Повідомлення" : ""}
            {lan?.language === "Cz" ? "Zpráva" : ""}
            {lan?.language === "En" ? 'Message' : ""}
             <div className="erorr">{messageB ? " *" : ""}</div>
          </label>{" "}
          <textarea
            className="inp"
            id="mas"
            onClick={() => messageBHandler(null)}
            placeholder={messageB ?? ""}
            name="message"
            onChange={(e) => messageHandler(e.target.value)}
            value={message}
            onBlur={blur}
          />
        </div>
        <button className={buttonClass} type="submit" ref={button}>
          <div className="cont1" id="cont1">SUBMIT</div>{" "}
          <div className="cont2" id="cont2">
            <svg xmlns="http://www.w3.org/2000/svg">
              <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
        </button>
      </form>
    </div>
  );
};
