import { useEffect, useRef } from "react";
import "./Experiance.css";
import { useLanguge } from "../../Context/Context";

export const Experience = () => {
  const box = useRef<HTMLDivElement | null>(null);
  const box2 = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const remove = () => {
      document.removeEventListener("scroll", f);
    };
    const f = () => {
      if (window.scrollY >= 800 && box.current?.classList.length !== 2) {
        box.current?.classList.add("show");
        box2.current?.classList.add("show");
      }
      if (window.scrollY >= 1300) {
        const f = document.getElementsByClassName("boxig");
        for (let i = 0; i < f.length; i++) {
          setTimeout(() => {
            f[i].classList.add("tran");
          }, 200 * i);
        }
        remove();
      }
    };
    document.addEventListener("scroll", f);
    return () => {
      remove();
    };
  }, []);
  const lan = useLanguge();
  return (
    <div>
      <h2 className="infotxt">
        {lan?.language === "Ua" ? "Досвід" : ""}
        {lan?.language === "Cz" ? "Zkušenosti" : ""}
        {lan?.language === "En" ? "Experience" : ""}
      </h2>
      <div className="mainbox">
        <div className="box2" ref={box}>
          <h3 className="work">
            {lan?.language === "Ua" ? "Комерційний досвід" : ""}
            {lan?.language === "Cz" ? "Obchodní zkušenosti" : ""}
            {lan?.language === "En" ? "Commercial experience" : ""}
          </h3>
          <p className="txtx">
            {lan?.language === "Ua"
              ? "Щодо комерційного досвіду, можу сказати, що, на жаль, у мене його немає, але у мене були фріланс-замовлення, які я успішно виконав і залишив замовника задоволеним, але я б дуже хотів спробувати попрацювати в компанії, і я навіть працював в команді над одним сайтом."
              : ""}
            {lan?.language === "Cz"
              ? "Co se týče komerčních zkušeností, mohu říci, že je bohužel nemám, ale měl jsem zakázky na volné noze, které jsem úspěšně dokončil a zanechal zákazníka spokojeného, ale opravdu bych chtěl zkusit pracovat ve firmě a dokonce jsem pracoval v týmu na jednom webu."
              : ""}
            {lan?.language === "En"
              ? "As for the commercial experience, I can say that unfortunately I don't have it, but I had freelance orders that I successfully completed and left the customer satisfied, but I would really like to try to work in a company and I even worked in a team on one web."
              : ""}
          </p>
        </div>
        <div className="freelance" ref={box2}>
        {lan?.language === "Ua" ? " Але я маю певний досвід роботи на фрілансі" : ""}
            {lan?.language === "Cz" ? " Ale mám nějaké zkušenosti na volné noze" : ""}
            {lan?.language === "En" ? "But i have some experience on freelance" : ""}
         
        </div>
      </div>
    </div>
  );
};
