import { Route, Routes } from "react-router-dom";
import { OutL } from "./Components/OutL/OutL";
import { About } from "./Components/About/About";
import { Resume } from "./Components/Resume/Resume";
import { Projects } from "./Components/Projects/Projects";
import { Contact } from "./Components/Contact/Contact";

function App() {
  return (
    <Routes>
      <Route path="/" element={<OutL></OutL>}>
         <Route index element={<About></About>}/>
         <Route path="resume" element={<Resume/>}/>
         <Route path="projects" element={<Projects></Projects>}/>
         <Route path="contact" element={<Contact></Contact>}/>
      </Route>
    </Routes>
  );
};

export default App;
